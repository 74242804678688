@font-face {
  font-family: "Georgia", serif;
  src: local('georgiabSerif'), url(./Fonts/Georgia.ttf) format('truetype');
}

@font-face {
  font-family: "Georgia";
  src: local('Georgia'), url(./Fonts/Georgia.ttf) format('truetype');
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis'), url(./Fonts/Lato.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis.ttf'), url(./Fonts/Metropolis-Medium.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis.ttf'), url(./Fonts/Metropolis-Bold.ttf) format('truetype');
  font-weight: 900;
}

.hiddenField,
.hiddenTable {
  display: none !important;
}

.pull-right {
  float: right !important;
}

.left-auto {
  left: auto !important;
  right: 1px !important;
}

input:disabled {
  background-color: #eceeef !important;
  opacity: 1;
  cursor: not-allowed;
  border: 1px solid;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mentions {
  margin: 1em 0;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
  width: 130px;
}

.mentions--singleLine .mentions__highlighter {
  padding: 1px;
  border: 2px inset transparent;
}

.mentions--singleLine .mentions__input {
  padding: 1px;
  border: 2px inset;
}

.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  padding: 9px;
  min-height: 63px;
}

.mentions--multiLine .mentions__input {
  border: 1px solid silver;
  padding: 9px;
  outline: 0;
  line-height: inherit;
}

.mentions__suggestions__list,
.ql-mention-list {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 10pt;
  list-style: none;
  padding-inline-start: 0;
}

.mentions__suggestions__item,
.ql-mention-list-item {
  padding: 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: pointer
}

.mentions__suggestions__item--focused,
.ql-mention-list-item.selected {
  background-color: #cee4e5;
}

.mentions__mention,
.mention {
  position: relative;
  z-index: 1;
  color: #15c;
  text-decoration: underline;
  pointer-events: none;
}

.ql-mention-list-container {
  position: absolute;
  z-index: 1;
  background-color: white;
  margin-top: 14px;
  min-width: 100px;
  right: 0px;
}

pre {
  padding: 10px;
  background-color: #eee;
  white-space: pre-wrap;
}

:not(pre)>code {
  font-family: monospace;
  background-color: #eee;
  padding: 3px;
}



.Hashtag {
  color: green;
}

.HashtagList {
  margin: 20px 0 0 0;
  list-style-type: none;
}

.HashtagListItem.current {
  color: blue;
}

.Mention {
  background: lightblue;
}

.MentionList {
  position: absolute;
  margin: 5px 0 0 0;
  padding: 0;
  box-shadow: 0 0 2px black;
  list-style: none;
  background: white;
}

.MentionListItem {
  padding: 10px;
}

.MentionListItem.current {
  background: lightgrey;
}

.draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9 {
  width: 40px !important;
  height: 40px !important;
}

.draftJsMentionPlugin__mentionSuggestions__2DWjA {
  justify-content: center !important;
}

.editorStyles_editor__bS05_ {
  border: none !important;
  box-shadow: none !important;
  border-left: 2px solid #d4d4d5 !important;
  border-radius: 0 !important;
}

.public-DraftEditorPlaceholder-inner {
  color: #aaa;
}

.wrap-words .text {
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.wrap-words .item {
  display: flex !important;
}

.custom-dropdown.ui.selection.dropdown .menu>.item {
  padding: 0 !important;
}

.no-border input {
  border: none;
}

.highlight {
  background: #f2984d;
}


.draftJsMentionPlugin__input__1Wxng {
  min-width: 350px;
}

@media (max-width: 768px) {

  .header {
    font-size: 15px !important;
  }

  .button {
    font-size: 0.9em !important;
  }

  .ui.selection.dropdown {
    min-width: 1.2em !important;
    padding: 0.7em 0.1em !important;
  }
}


.tox-statusbar__text-container {
  display: none !important;
}

.bg_light_red {
  background: #FFE0E0;
}
.bg_light_red td{
  font-weight: bold;
  color: #000;
}

.rc-table tr.bg_light_red:hover {
  background: #FFABAB;
}

/* .bg_light_red {
  background: #e25561;
} */

.rc-table tr.bg_light_red:hover {
  background: #FFABAB;
}

.bg_light_yellow {
  background: #E8F8E0;
}

.bg_light_yellow td{
  font-weight: bold;
  color: #000;
}

.rc-table tr.bg_light_yellow:hover {
  background: #D0F1C1;
}

.bg_none {
  background: #fff;
}

.bg_none td{
  font-weight: bold;
  color: #000;
}

.rc-table tr.bg_none:hover {
  background: #fff;
}

/* =================== */

/* button.ui.button.editButton {
  background-color: #ff92a0;
  color: white;
  padding: 12px 15px;
  border: none;
  border-radius: 5px;
  margin-left: auto;
  display: block;
} */

button.ui.button.editButton {
  background-color: #f39ca3;
  color: white;
  padding: 12px 15px;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
  /* Adjust the margin as needed for spacing */
}

.ansBox textarea {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none !important;
}

.ansBox textarea:focus {
  outline: none;
}

.questionAnsBox .ant-row.ant-form-item {
  margin-bottom: 0;
}


.questionBox .questText input {
  width: 100%;
  margin-bottom: 10px;
  border: none !important;
}

.questionWrp {
  margin: 13px 0;
  border-bottom: 1px solid #e7e1e1;
  padding-bottom: 15px;
}

.questionAnsBox {
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  padding: 8px;
  margin: 23px 0;
}

button.ui.button.generateBtn {
  background-color: #ff0d2c;
  color: white;
  padding: 12px 15px;
  border: none;
  border-radius: 5px;
  display: block;
  margin-left: auto;
}

.iconWrp button.syncBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
  background-color: #4096ff;
  padding: 10px;
  margin: 10px 0px 10px auto;
}

.iconWrp button.syncBtn i {
  margin: 0 !important;
  color: #fff;
}

.field-error {
  color: red;
  margin: 10px 0px;
}

.question-filed,
.answer-label,
.question,
.answer-generation,
.update-btn,
.textarea-box {
  margin: 10px 5px;
}

.textarea-box {
  padding: 10px 5px;
  height: 130px;
  border: unset !important;
  font-size: 16px;
  line-height: 21px;
  outline: unset;
}

.question-filed {
  padding: 5px;
  border: unset !important;
}


.question-filed:focus-visible {
  outline: unset;
}

.secondary {
  margin: 15px 5px;
  padding: 8px;
}

.update-btn {
  padding: 12px 15px;
  display: block;
  margin-left: auto;
  background-color: #ff0d2c;
  border: unset;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
}

.loader-box {
  height: 600px;
  text-align: center;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.end-section {
  border-bottom: 1px solid  #e7e1e1;
  margin: 15px 0px;
}

.question-filed {
  padding: 10px 5px;
}

.answer-generation {
  color: #262424;
  font-size: 14px;
  font-weight: 600;
  background-color: #dcdcdc;
  padding: 5px 15px;
  border-radius: 5px;
}

.answer-label {
  color: #000;
  font-weight: 600;
  margin: 0px 5px 10px 5px;
  font-size: 15px;
}

.question-filed,
.textarea-box {
  border: 1px solid #22242626;
  border-radius: 5px;
}

.question {
  margin-bottom: 0px;
  font-weight: 600;
  color: #000;
  font-size: 15px;
}

.syncBtn-active i {
  animation: rotate 5s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
